import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import $ from 'jquery';
import Img from 'gatsby-image';
import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Info (props) {
  const node = props.node;

  useEffect(() => {
    let slideSettings = {};

    if(node.__typename === 'WordPressAcf_carousel'){
      slideSettings = {
        adaptiveHeight: node.optional_slide_settings ? node.optional_slide_settings.adaptive_height : true,
        arrows: false,
        autoplay : node.optional_slide_settings ? node.optional_slide_settings.autoplay : false,
        dots: true,
        draggable: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

      if (node.optional_slide_settings && node.optional_slide_settings.autoplay){
        slideSettings['autoplay'] = node.optional_slide_settings.autoplay;
        slideSettings['autoplaySpeed'] = node.optional_slide_settings.autoplay_speed;
      }

      let adaptiveCSS = node.optional_slide_settings ? (node.optional_slide_settings.adaptive_height ? '' : 'no-adaptive') : ''

      $(".slick-slider").addClass(adaptiveCSS).slick(slideSettings);
    }

    return () => {
      slideSettings = {}  
      $(".slick-slider").slick('unslick');  
    }
  },[node])

  const sectionClass = typeName => {
    switch (typeName) {
      case 'WordPressAcf_info_with_thumbs_images':
        return 'alt';
        // eslint-disable-next-line no-unreachable
        break;
      case 'WordPressAcf_info_with_image':
        if (node.image_position === 'Left') {
          return 'rtl';
        } else {
          return '';
        }
        // eslint-disable-next-line no-unreachable
        break;
      /*case 'WordPressAcf_testimonials':
        return 'rtl';
        // eslint-disable-next-line no-unreachable
        break;*/
      case 'WordPressAcf_carousel':
        if(node.rtl) {
          return 'rtl';
        } else {
          return '';
        }
          // eslint-disable-next-line no-unreachable
          break;
      default:
        return '';
    }
  };

  

  const imageClass =
    node.image_background_position === undefined
      ? 'image'
      : 'image ' + node.image_background_position;

  let imageAboveTextClass =
    node.image_above_text_width === undefined ||
    node.image_above_text_width === "'50'"
      ? `image-above-text width-50 ${node.image_above_text_mobile_align}`
      : `image-above-text width-${node.image_above_text_width} ${
          node.image_above_text_mobile_align
        }`;

  let slides;
  if (node.slides) {
    slides = Array.from(node.slides);
  }

  return (
    <section
      id={
        node.eyebrow
          ? 'section-' + node.eyebrow.replace(/\s+/g, '-').toLowerCase()
          : null
      }
      className={`section-info ${sectionClass(node.__typename)}`}
    >
      {'image' in node && node.image_position === 'Left' && (
        <div className='image'>
          <Img fluid={node.image.localFile.childCloudinaryAsset.fluid} />
        </div>
      )}
      
      {/*'testimonials' in node && [
        <div
          key='image-slider'
          className='image slider'
          style={{
            backgroundImage: 'none',
            backgroundColor: '#151A20',
            color: '#ffffff'
          }}
        >
          <Slider {...settings}>
            {testimonials.map((testimony, i) => {
              let icon = quote_icon;
              let iconClass = 'quote-icon';
              return (
                <div key={i}>
                  <img
                    className={iconClass}
                    src={icon}
                    alt='quote'
                    width='90'
                    height='auto'
                  />
                  <blockquote>{testimony.quote}</blockquote>
                  <cite>{testimony.author}</cite>
                </div>
              );
            })}
          </Slider>
        </div>
      ]*/}
      {'slides' in node && [
        <div
          key='carousel-slider'
          className='image carousel slider'
          style={{
            backgroundImage: 'none',
            backgroundColor: '#151A20',
            color: '#ffffff'
          }}
        >
          <div className="slick-slider">
            {slides.map((slide, i) => {
              return (
                <div key={i}>
                  {slide.image !== null && (
                  <Img
                    fluid={slide.image.localFile.childCloudinaryAsset.fluid}
                      className='gatsby-image-relative slider-img'
                  />
                  )}
                  <span dangerouslySetInnerHTML={{ __html: slide.text}}/>
                </div>
              );
            })}
          </div>
        </div>
      ]}
      <div className='content'>
        {node.eyebrow && <div className='content--line' />}
        <div className='content--main'>
          {node.eyebrow && (
            <p className='eyebrow'>
              <span>{node.eyebrow}</span>
            </p>
          )}
          {'image_above_text' in node && node.image_above_text && (
            <div className={imageAboveTextClass}>
              <Img
                fluid={node.image_above_text.localFile.childCloudinaryAsset.fluid}
                className='gatsby-image-relative'
              />
            </div>
          )}
          <span dangerouslySetInnerHTML={{ __html: node.text }} />
          {node.button === true && (
            <a href={node.button_link.url} target={node.button_link.target}>
              <button>{node.button_link.title}</button>
            </a>
          )}
        </div>
      </div>

      {'image' in node && node.image_position !== 'Left' && (
        <div className={imageClass}>
          <Img fluid={node.image.localFile.childCloudinaryAsset.fluid} />
        </div>
      )}

      {'thumbnails' in node && node.thumbnails.length > 0 && (
        <div className='thumbs'>
          {node.thumbnails.map((thumbnail, i) => {
            const fluid = thumbnail.image.localFile.childCloudinaryAsset.fluid;
            const thumbImageClass =
              thumbnail.image_background_position === undefined
                ? 'thumb'
                : 'thumb ' + thumbnail.image_background_position;

            return (
              <span key={i}>
                {thumbnail.link ? (
                  <a href={thumbnail.link}>
                    <div className={thumbImageClass}>
                      <Img fluid={fluid} />
                      <div className='thumb--content' />
                    </div>
                  </a>
                ) : (
                  <div className={thumbImageClass}>
                    <Img fluid={fluid} />
                    <div className='thumb--content' />
                  </div>
                )}
              </span>
            );
          })}
        </div>
      )}
    </section>
  );
};

export const infoQuery = graphql`
  fragment Info on wordpress__PAGE {
    acf {
      content_page {
        __typename
        ... on WordPressAcf_info_with_image {
          eyebrow
          image_above_text {
            localFile {
              childCloudinaryAsset {
                fluid(maxWidth: 1000) {
                  ...CloudinaryAssetFluid
                }
              }
            }
          }
          image_above_text_width
          image_above_text_mobile_align
          text
          image_position
          image {
            localFile {
              childCloudinaryAsset {
                fluid(maxWidth: 1080) {
                  ...CloudinaryAssetFluid
                }
              }
            }
          }
          button
          button_link {
            url
            title
            target
          }
        }
        ... on WordPressAcf_info_with_thumbs_images {
          eyebrow
          image {
            localFile {
              childCloudinaryAsset {
                fluid(maxWidth: 1080) {
                  ...CloudinaryAssetFluid
                }
              }
            }
          }
          image_background_position
          text
          button
          button_link {
            url
            title
            target
          }
          thumbnails {
            image {
              localFile {
                childCloudinaryAsset {
                  fluid(maxWidth: 1080) {
                    ...CloudinaryAssetFluid
                  }
                }
              }
            }
            image_background_position
          }
          thumbnails_fwm_logo_color
        }
        ... on WordPressAcf_info_with_thumbs {
          eyebrow
          text
          button
          button_link {
            url
            title
            target
          }
          thumbnails {
            link
            image {
              localFile {
                childCloudinaryAsset {
                  fluid(maxWidth: 1080) {
                    ...CloudinaryAssetFluid
                  }
                }
              }
            }
            image_background_position
          }
          thumbnails_fwm_logo_color
        }
        ... on WordPressAcf_carousel {
          eyebrow
          text
          button
          button_link {
            title
            url
            target
          }
          rtl
          slides {
            image {
              localFile {
                childCloudinaryAsset {
                  fluid(maxWidth: 575) {
                    ...CloudinaryAssetFluid
                  }
                }
              }
            }
            text
          }
          optional_slide_settings {
            adaptive_height
            autoplay
            autoplay_speed
          }
        }
      }
    }
  }
`;
