import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

export default ({ node }) => {
  const backgroundImage = node.background_image
    ? node.background_image.localFile.childCloudinaryAsset.fluid
    : null;
  const backgroundColor = node.background_color ? node.background_color : null;
  const className =
    node.text_color === 'dark'
      ? 'section-interstitial text-dark'
      : 'section-interstitial';

  let buttonClass = '';

  if (node.button_style === 'default') {
    if (node.text_color !== 'dark') {
      buttonClass = 'button-white';
    }
  } else {
    buttonClass = 'button-arrow';
  }

  return (
    <section
      id={
        node.eyebrow
          ? 'section-' + node.eyebrow.replace(/\s+/g, '-').toLowerCase()
          : null
      }
      className={className}
      style={{
        backgroundImage: backgroundImage,
        backgroundColor: backgroundColor
      }}
    >
      {backgroundImage && (
        <div className="background-image">
          <Img fluid={backgroundImage} />
        </div>
      )}

      <div className="content">
        {node.eyebrow && <p className="eyebrow">{node.eyebrow}</p>}
        {node.eyebrow && node.line_under_eyebrow && (
          <div className="line-under-eyebrow" />
        )}

        <span dangerouslySetInnerHTML={{ __html: node.text }} />
        {node.button === true && (
          <a href={node.button_link.url} target={node.button_link.target}>
            <button className={buttonClass}>
              {node.button_link.title}
              {node.button_style !== 'default' && (
                <i className="fas fa-arrow-right" />
              )}
            </button>
          </a>
        )}
      </div>
    </section>
  );
};

export const interstitialQuery = graphql`
  fragment Interstitial on wordpress__PAGE {
    acf {
      content_page {
        __typename
        ... on WordPressAcf_interstitial {
          eyebrow
          line_under_eyebrow
          text_color
          text
          button
          button_link {
            url
            title
            target
          }
          button_style
          background_color
          background_image {
            localFile {
              childCloudinaryAsset {
                fluid(maxWidth: 2500, transformations: "q_90") {
                  ...CloudinaryAssetFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
