import React, { useState, useEffect, useRef, useContext } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import detectIt from 'detect-it';
import $ from 'jquery';
import VideoPlayer from './VideoPlayer';
import { HeaderContext } from '../context/HeaderContext';

function Hero(props) {

  const [videoBgPaused, setVideoBgPaused] = useState(false);
  const [heroVideoPlaying, setHeroVideoPlaying] = useState(false);
  const ctxRef = useRef(useContext(HeaderContext));
  const section = React.useRef();
  const video = React.useRef();
  const content = React.useRef();

  useEffect(() => {
    var context = ctxRef;

    context.current.resetLogoLight();
    //this.visible = false;
    if (detectIt.passiveEvents === true) {
      window.addEventListener('scroll', handleScroll, {
        capture: false,
        passive: true
      });
    } else {
      window.addEventListener('scroll', handleScroll, false);
    }
    window.addEventListener('resize', handleScroll);
    handleScroll();

    return () => {
      var context = ctxRef;

      context.current.resetLogoDark();
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    }

    function handleScroll() {
      if (!window) return;
      
      const _section = section.current;
      if (!_section) return;
      
      const currentScrollPos = getPageYOffset();
      const thisY = _section.offsetTop;
      const thisH = _section.offsetHeight;
  
      if (thisY < 100) {
        if (currentScrollPos > thisH) {
          setVideoBgPaused(true);
        } else {
          if (!heroVideoPlaying) {
            setVideoBgPaused(false);
          }
        }
      }
  
      if (heroVideoPlaying) {
        $(section.current).animate(
          {
            'min-height': calcVideoHeight()
          },
          0
        );
      }
    }
  },[heroVideoPlaying]);
  

  function getPageYOffset() {
    return typeof window !== `undefined` ? window.pageYOffset : 0;
  }

  function calcVideoHeight() {
    if (!window) return;
    // ! Sometimes this needs 9px offset, don't know why... :-/
    return window.innerWidth * 0.5625 + 'px';
  };


  function loadHeroVideo() {
    $(content.current).fadeOut();
    $(section.current).animate(
      {
        'min-height': calcVideoHeight()
      },
      200,
      'swing'
    );
    setHeroVideoPlaying(true);
  };

  function unloadHeroVideo() {
    $(content.current).fadeIn();
    $(section.current).animate(
      {
        'min-height': '90vh'
      },
      200,
      'swing'
    );
    setHeroVideoPlaying(false);
    setVideoBgPaused(false);
  };


  const node = props.node;
  const className =
    node.__typename === 'WordPressAcf_hero'
      ? 'section-full ' + node.hero_height
      : 'section-body';
  const backgroundImage = node.background_image
    ? node.background_image.localFile.childCloudinaryAsset.fluid
    : null;
  const color = node.text_color ? node.text_color : null;

  return (
    <section
      id={
        node.eyebrow
          ? 'section-' + node.eyebrow.replace(/\s+/g, '-').toLowerCase()
          : null
      }
      className={className}
      style={{ color: color }}
      ref={section}
    >
      {/* Background Image */}
      {backgroundImage && !node.background_video && (
        <div className='background-image'>
          <Img fluid={backgroundImage} />
        </div>
      )}

      {/* Video Player */}
      {(node.background_video_id || node.main_video_id) && (
        <VideoPlayer
          bgVideoID={node.background_video_id}
          mainVideoID={node.main_video_id}
          heroVideoPlaying={heroVideoPlaying}
          videoBgPaused={videoBgPaused}
          onMainVideoPause={unloadHeroVideo}
          ref={video}
        />
      )}

      <div className='content' ref={content}>
        {node.eyebrow && node.__typename !== 'WordPressAcf_hero' && (
          <div className='content--line' />
        )}
        <div className='content--main'>
          {node.eyebrow && <p className='eyebrow'>{node.eyebrow}</p>}
          {node.add_image_heading === true && (
            <img
              src={
                node.image_heading.localFile.childCloudinaryAsset.fluid.src
              }
              alt={node.image_heading.alt_text}
              className='heading--image'
            />
          )}
          <span
            className='content--main__text'
            dangerouslySetInnerHTML={{
              __html: node.text
            }}
          />
          {node.image && (
            <img
              src={node.image.localFile.childCloudinaryAsset.fluid.src}
              alt={node.image.alt_text}
              className='partners--image'
            />
          )}

          {/* Button (default) */}
          {node.button === true &&
            !node.iframe_embed &&
            !node.background_video_id &&
            !node.main_video_id && (
              <a href={node.button_link.url} target={node.button_link.target}>
                <button>{node.button_link.title}</button>
              </a>
            )}
          {/* Button (default) */}
          {node.button === true && (node.background_video_id || node.main_video_id) && (
            <div
              role="button"
              tabIndex="0"
              className='btn-play'
              onClick={e => {
                e.preventDefault();
                loadHeroVideo();
              }}
              onKeyPress={null}
            >
              <button>{node.button_link.title}</button>
              {/* <img src={btnPlay} alt='play button' className='btn-play' />
              <p>{node.button_link.title}</p> */}
            </div>
          )}

          {/* Button (video modal) */}
          {node.button === true && node.iframe_embed && (
            <div>
              {/* <Modal
                button_text={node.button_link.title}
                iframe_embed={node.iframe_embed}
                iframe_width={node.iframe_width}
                iframe_height={node.iframe_height}
              /> */}
            </div>
          )}
          {node.partner_logos && (
            <div className='grid-partners'>
              {node.partner_logos.map((logos, i) => {
                if(logos.logo.localFile){
                  return (
                    <div key={i} className='item'>
                      <img
                        className='image-responsive'
                        src={logos.logo.localFile.childCloudinaryAsset.fixed.src}
                        alt={logos.logo.alt_text}
                      />
                    </div>
                  )
                } else {
                  return null;
                }
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}


export default Hero;

export const heroQuery = graphql`
  fragment Hero on wordpress__PAGE {
    acf {
      content_page {
        __typename
        ... on WordPressAcf_hero {
          background_image {
            localFile {
              childCloudinaryAsset{
                fluid(maxWidth: 2500) {
                  ...CloudinaryAssetFluid
                }
              }
            }
            alt_text
          }
          background_video_id
          main_video_id
          hero_height
          add_image_heading
          image_heading {
            localFile {
              childCloudinaryAsset {
                fluid {
                  ...CloudinaryAssetFluid
                }
              }
            }
            alt_text
          }
          eyebrow
          text
          text_color
          button
          button_link {
            title
            url
            target
          }
          iframe_embed
          iframe_width
          iframe_height
        }
        ... on WordPressAcf_partners {
          eyebrow
          text
          partner_logos {
            logo {
              localFile {
                childCloudinaryAsset {
                  fixed(width:145, transformations: ["w_145"]) {
                    ...CloudinaryAssetFixed
                  }
                }
              }
              alt_text
            }
          }
        }
      }
    }
  }
`;
