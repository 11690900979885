import React, { Component } from 'react';
import cloudinary from 'cloudinary-core';
import 'cloudinary-video-player/dist/cld-video-player.js';
import 'cloudinary-video-player/dist/cld-video-player.min.css';
//import SizzleReelCaptions from '../captions/sizzle-reel-captions.vtt'
import $ from 'jquery';
import { isMobileDevice } from '../utils/helpers';
// import blackSquare from '../images/black-square.png';

export default class VideoPlayer extends Component {
  componentDidMount() {
    this.animating = false;

    const { bgVideoID, mainVideoID } = this.props;
    this.cl = cloudinary.Cloudinary.new({
      cloud_name: 'judah-firewood',
      secure: true
    });
    if (bgVideoID) {
      if (isMobileDevice()) {
        this.playerLoop = this.cl
          .videoPlayer('cl-loop', {
            loop: true,
            controls: false,
            muted: true
          })
          .source(bgVideoID, {
            sourceTypes: ['hls'],
            transformation: {
              streaming_profile: 'full_hd'
            },
            poster:
              'https://res.cloudinary.com/judah-firewood/image/upload/v1562793172/black-square.png'
          });
      } else {
        this.playerLoop = this.cl
          .videoPlayer('cl-loop', {
            loop: true,
            controls: false,
            muted: true
          })
          .source(bgVideoID, {
            sourceTypes: ['hls'],
            transformation: {
              streaming_profile: 'true_hd_custom'
            },
            poster:
              'https://res.cloudinary.com/judah-firewood/image/upload/v1562793172/black-square.png'
          });
      }
    }
    if (mainVideoID) {
      if (isMobileDevice()) {
        this.playerMain = this.cl
          .videoPlayer('cl-main', {
            loop: false,
            controls: true,
            muted: false,
            hideContextMenu: true
          })
          .source(mainVideoID, {
            sourceTypes: ['hls'],
            transformation: {
              streaming_profile: 'full_hd'
            },
            poster:
              'https://res.cloudinary.com/judah-firewood/image/upload/v1562793172/black-square.png'
          });
      } else {
        this.playerMain = this.cl
          .videoPlayer('cl-main', {
            loop: false,
            controls: true,
            muted: false,
            hideContextMenu: true
          })
          .source(mainVideoID, {
            sourceTypes: ['hls'],
            transformation: {
              streaming_profile: 'true_hd_custom'
            },
            poster:
              'https://res.cloudinary.com/judah-firewood/image/upload/v1562793172/black-square.png'
          });
      }

      this.playerMain.on(
        ['pause', 'playing', 'seeking', 'fullscreenchange'],
        this.onVideoChange
      );

      $('.video-player').fadeOut(0);
    }
  }

  componentWillUnmount() {
    // unload video components
    delete this.playerMain;
    delete this.playerLoop;
  }

  startUnloadTimer = () => {
    this.unloadTimer = setTimeout(() => {
      this.unloadMainVideo();
    }, 100);
  };

  clearPauseTimer() {
    clearTimeout(this.unloadTimer);
  }

  unloadMainVideo() {
    $(this.videoLoop)
      .delay(500)
      .fadeIn();
    $('.video-player').fadeOut(200);
    this.videoLoop.play();
    this.props.onMainVideoPause();
  }

  onVideoChange = e => {
    const eType = e.type;
    // console.log(eType, this.playerMain);

    if (
      eType === 'pause' &&
      this.playerMain &&
      !this.playerMain.isMaximized()
    ) {
      this.startUnloadTimer();
    }
    if (eType === 'seeking') {
      this.clearPauseTimer();
    }
    if (eType === 'fullscreenchange') {
      this.playerMain.play();
    }
  };

  render() {
    // console.log(this.videoLoop);
    const { videoBgPaused, heroVideoPlaying } = this.props;
    const customClass =
      this.playerMain && this.playerMain.isMaximized() ? 'fullscreen' : '';

    if (this.videoLoop) {
      if (!videoBgPaused) {
        var playPromise = this.videoLoop.play();
        if (playPromise !== undefined) {
          playPromise.then(_ => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
          });
        }
      }
      if (videoBgPaused) {
        this.videoLoop.pause();
      }
    }
    if (this.videoMain && heroVideoPlaying) {
      if (!this.animating) {
        this.animating = true;
        $(this.videoLoop)
          .delay(200)
          .fadeOut(200);
        $('.video-player')
          .delay(600)
          .fadeIn(200, () => {
            this.animating = false;
          });
        this.loadTimer = setTimeout(() => {
          this.playerMain.play();
          if (isMobileDevice()) {
            this.playerMain.maximize();
          }
        }, 800);
      }
    }

    return (
      <div className={`hero-video ${customClass}`}>
        <div className='video-background'>
          <video
            playsInline
            autoPlay
            loop
            muted
            ref={node => (this.videoLoop = node)}
            id='cl-loop'
            className='video-loop'
          />
          <div className='overlay' />
        </div>
        <div className='video-player'>
          <video
            ref={node => (this.videoMain = node)}
            id='cl-main'
            className='video-main'
          >
            <track src="/captions/sizzle-reel-captions.vtt" kind="captions" />
          </video>
        </div>
      </div>
    );
  }
}
