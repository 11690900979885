import React from "react";
import { graphql } from "gatsby";

export default ({ node, index }) => {
  const className = index === 0 ? "section-body first-section" : "section-body";

  return (
    <section className={className}>
      <div className="content">
        {node.eyebrow && <div className="content--line" />}
        <div className="content--main">
          {node.eyebrow && (
            <p className="eyebrow">
              <span>{node.eyebrow}</span>
            </p>
          )}
          <span dangerouslySetInnerHTML={{ __html: node.text }} />
        </div>
      </div>
    </section>
  );
};

export const bodyQuery = graphql`
  fragment Body on wordpress__PAGE {
    acf {
      content_page {
        __typename
        ... on WordPressAcf_body {
          eyebrow
          text
        }
      }
    }
  }
`;
